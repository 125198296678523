<template>
    <el-dialog title="操作日志" :visible.sync="deviceLogPageVisible" top="0" width="1200px"
        :close-on-click-modal="false" :destroy-on-close=true>
        <div class="panel-style" style="height: 560px;">
            <el-form :inline="true" :model="searchDto" size="mini">
                <el-form-item label="操作名称" prop="operationCode">
                    <el-select class="input" v-model="searchDto.operationCode" clearable filterable placeholder="请选择操作名称">
                        <el-option v-for="item in operationNameList" :key="item.operationCode" :label="item.operationName"
                               :value="item.operationCode"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="操作时间" prop="operationTime">
                    <el-date-picker v-model="updateTimeRange" type="daterange" value-format="yyyy-MM-dd" class="input"
                        :picker-options="pickerOptions" start-placeholder="开始日期" range-separator="-" end-placeholder="结束日期" >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryOperationLogList">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table border stripe size="small" :data="tableData" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                style="width: 100%" v-loading="listLoading" max-height="450px">
                <el-table-column prop="deviceSn" label="逆变器SN号" width="210px"/>
                <el-table-column prop="productModelName" label="产品型号" width="150px"/>
                <el-table-column prop="operationName" label="操作名称" min-width="100px">
                    <template slot-scope="scope">
                        <el-button @click="openCommandParamDetailPage(scope.row)" style="height:20px; padding:0"
                            size="mini" type="text">{{scope.row.operationName}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="operationRespCode" label="操作结果" width="80px">
                    <template slot-scope="scope">
                        <div>
                            <span v-if="scope.row.operationRespCode==='200'" style="color: #00D30F">成功</span>
                            <span v-else-if="scope.row.operationRespCode==='500'" style="color: #d5040d">失败</span>
                            <span v-else-if="scope.row.operationRespCode==='600'" style="color: #d5040d">未响应</span>
                            <span v-else-if="scope.row.operationRespCode==='700'" style="color: #00D30F">已下发</span>
                            <span v-else>--</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="operationUserName" label="操作人" width="160px"/>
                <el-table-column prop="operationTime" label="操作时间" width="150px" align="center"/>
            </el-table>
            <div class="pagination">
                <el-pagination background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                    :current-page.sync="searchDto.pageNum"
                    :page-sizes="[10, 50, 100]"
                    @current-change="handleCurrentChangeEvent"
                    @size-change="handleSizeChangeEvent"/>
            </div>
        </div>
        <EsaioOperationLogParamDetail ref="esaioOperationLogParamDetailPage"/>
    </el-dialog>
</template>
<script>
import {queryRemovedDeviceOpLogList, queryOperationLogNameList} from "@/api/esaio/oplog/operationLogApi";
import EsaioOperationLogParamDetail from '@/views/esaio/oplog/operation-log-param-detail.vue';
import global from "@/components/Global";
import message from "@/utils/message";

export default {

    name: 'removed-device-log',
    components: {

        EsaioOperationLogParamDetail
    },
    data() {

        return {

            deviceLogPageVisible: false,
            operationNameList: [],
            updateTimeRange: [],
            searchDto: {

                pageNum: 1,
                pageSize: 10,
                deviceSn: '',
                operationCode: null,
                startTime: null,
                endTime: null
            },
            tableData: [],
            tableTotal: 0,
            listLoading: false,
            pickerOptions: {

                disabledDate(time) {

                    return time.getTime() > Date.now();
                }
            }
        }
    },
    methods: {

        initRemovedDeviceLogPage(row) {

            this.deviceLogPageVisible = true;
            this.searchDto.deviceSn = row.serialNumber;
            this.queryOperationLogNameList(row.productModelId);
            this.queryOperationLogList();
        },
        queryOperationLogNameList(productModelId) {

            let params = {

                productModelId: productModelId
            };
            queryOperationLogNameList(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.operationNameList = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请重试');
                console.error(err);
            });
        },
        queryOperationLogList() {

            this.searchDto.startTime = this.updateTimeRange ? this.updateTimeRange[0] : null;
            this.searchDto.endTime = this.updateTimeRange ? this.updateTimeRange[1] : null;
            this.listLoading = true;
            queryRemovedDeviceOpLogList(this.searchDto).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch((err) => {

                this.listLoading = false;
                message.error("请求失败，请重试");
                console.error(err);
            });
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.queryOperationLogList();
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.queryOperationLogList();
        },
        openCommandParamDetailPage(row) {

            this.$refs.esaioOperationLogParamDetailPage.initCommandParamDetailPage(row);
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination {

    margin-top: 15px;
    text-align: right;
}
</style>
