<template>
    <div class="panel-style device">
        <el-form :inline="true" size="mini">
            <el-form-item label="产品型号">
                <el-select placeholder="全部" clearable v-model="searchForm.productModelId">
                    <el-option v-for="item in productModelOption" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备SN号">
                <el-input v-model="searchForm.serialNumber" placeholder="设备SN号" clearable style="width: 230px;"/>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker v-model="createTimeRange" type="daterange" value-format="yyyy-MM-dd"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQueryClickEvent">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border size="small" stripe style="width: 100%" max-height="550px"
                  :header-cell-style="{background: '#eef1f6', color: '#606266'}" v-loading="listLoading"
                  @sort-change="handleSortChangeEvent">
            <el-table-column prop="serialNumber" label="设备SN号" sortable="custom"/>
            <el-table-column prop="comstickNumber" label="通讯棒编号" align="center" :formatter="comstickFormatter" />
            <el-table-column prop="comstickKey" label="通讯棒key" align="center" :formatter="comstickFormatter" width="120px"/>
            <el-table-column prop="productModelName" label="产品型号" width="130px"/>
            <el-table-column prop="createTime" label="创建时间" width="150px" align="center" sortable="custom"/>
            <el-table-column prop="updateTime" label="更新时间" width="150px" align="center"/>
            <el-table-column fixed="right" label="操作" width="100px" align="center">
                <template slot-scope="scope">
                    <div class="option-btn-div">
                        <i class="el-icon-my-info option-btn-normal" @click="openDeviceDetailPage(scope.row)"/>
                        <i class="el-icon-document option-btn-normal" @click="openRemovedDeviceLogPage(scope.row)"/>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
                :current-page.sync="searchForm.pageNum"
                :page-sizes="[10, 50, 100]"
                :page-size="searchForm.pageSize"
                @size-change="handleSizeChangeEvent"
                @current-change="handleCurrentChangeEvent"/>
        </div>
        <removed-device-data-detail ref="deviceDataDetailPage" @queryEsaioDeviceInfoList="queryEsaioDeviceInfoList"/>
        <remover-device-log ref="removedDeviceLogPage"/>
    </div>
</template>
<script>
import {queryRemovedEsaioDeviceInfoList, queryProductModelList} from '@/api/esaio/device/esaioDeviceInfo';
import RemovedDeviceDataDetail from '@/views/esaio/device/device-detail/removed-device-data-detail.vue';
import RemoverDeviceLog from '@/views/esaio/oplog/removed-device-log.vue';
import Global from '@/components/Global';

export default {

    name: 'removed-device-list',
    components: {

        RemovedDeviceDataDetail,
        RemoverDeviceLog
    },
    data() {

        return {

            curOptionDeviceInfo: null,
            commandLogPageVisible: false,
            devicePointDataPageVisible: false,
            listLoading: false,
            productModelOption: [],
            selectedDeviceSn: '',
            productModelCode: '',
            searchForm: {

                pageNum: 1,
                pageSize: 10,
                stationName: '',
                productModelId: null,
                serialNumber: null,
                createTimeStart: '',
                createTimeEnd: '',
                sortColumn: '',
                sort: ''
            },
            tableData: [],
            tableTotal: 0,
            createTimeRange: '',
            pickerOptions: {

                disabledDate(time) {

                    return time.getTime() > Date.now();
                }
            }
        };
    },
    methods: {

        handleSortChangeEvent({prop, order}) {

            if (order) {

                this.searchForm.sortColumn = prop;
                this.searchForm.sort = order;
            } else {

                this.searchForm.sortColumn = undefined;
                this.searchForm.sort = undefined;
            }
            this.queryEsaioDeviceInfoList();
        },
        handleQueryClickEvent() {

            this.searchForm.pageNum = 1;
            this.queryEsaioDeviceInfoList();
        },
        queryEsaioDeviceInfoList() {

            if (this.createTimeRange && this.createTimeRange.length > 1) {

                this.searchForm.createTimeStart = this.createTimeRange[0];
                this.searchForm.createTimeEnd = this.createTimeRange[1];
            } else {

                this.searchForm.createTimeStart = null;
                this.searchForm.createTimeEnd = null;
            }
            queryRemovedEsaioDeviceInfoList(this.searchForm).then(res => {

                if (res.code === Global.response_status_success_obj) {

                    this.tableData = res.data.result;
                    this.tableTotal = res.data.total;
                } else {

                    this.$message({

                        type: 'error',
                        showClose: true,
                        message: res.msg
                    });
                }
            }).catch(err => {

                this.$message({

                    type: 'error',
                    showClose: true,
                    message: '请求失败，请稍后再试'
                });
                console.error(err);
            });
        },
        initTableData() {

            this.searchForm.pageNum = 1;
            this.searchForm.pageSize = 10;
            this.queryEsaioDeviceInfoList();
        },
        handleSizeChangeEvent(val) {

            this.searchForm.pageSize = val;
            this.queryEsaioDeviceInfoList();
        },
        handleCurrentChangeEvent(val) {

            this.searchForm.pageNum = val;
            this.queryEsaioDeviceInfoList();
        },
        openDeviceDetailPage(row) {

            this.$refs.deviceDataDetailPage.initDeviceDetailPage(row);
        },
        openRemovedDeviceLogPage(row) {

            this.$refs.removedDeviceLogPage.initRemovedDeviceLogPage(row);
        },
        comstickFormatter(row, column, cellValue) {

            if (row.commMode === '0' && cellValue) {

                return cellValue;
            }
            return '--';
        },
        /**
         * 初始化产品型号列表.
         */
        initProductModelOption() {

            queryProductModelList().then(res => {

                if (res.code === Global.response_status_success_obj) {
                    // 目前在售设备只有DR-5K-TL-AIO、DR5KTLAIOA02、DR5KTLAIOS05
                    let codeArr = ['DR-5K-TL-AIO', 'DR5KTLAIOA02', 'DR5KTLAIOS05'];
                    this.productModelOption = res.data.filter(obj => codeArr.includes(obj.code));
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        }
    },
    mounted() {

        this.initTableData();
        this.initProductModelOption();
    }
}
</script>
<style lang="scss" scoped>
.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
.el-icon-my-info {

    height: 18px;
    width: 18px;
    background-image: url('../../../assets/icon/button/info.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.online-style {

    width: 10px;
    height: 10px;
    background: #67C23A;
    border-radius: 50%;
    margin-left: 45%;
    cursor: pointer;
}
.offline-style {

    width: 10px;
    height: 10px;
    background: #B3ABAB;
    border-radius: 50%;
    margin-left: 45%;
    cursor: pointer;
}
</style>
